/* InputStyles.css */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fill-red {
  fill: red;
}

.cccc:hover {
  background-color: #E7F3FF !important;
}

.custom-seekbar .vjs-play-progress:before {
  background-color: rgb(226, 30, 38) !important;
}


/* video player */
/* App.css */

.video-container {
  position: relative;
}

.video-player {
  width: 100%;
}

.progress-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  width: 95%;
  margin: auto;
  height: 4px;
  background-color: #ddd;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2px;
  z-index: 1;
}

.progress {
  width: 0%;
  height: 100%;
  background-color: red;
  box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.7);
}

.buffered {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

/* App.css */

/* Hide the default timeline */
.video-player::-webkit-media-controls-timeline,
.video-player::-webkit-media-controls-timeline-progress-bar {
  display: none;
}

/* Hide the progress bar by default */
.progress-bar {
  display: none;
}

/* Show progress bar when the video is hovered */
.video-container:hover .progress-bar {
  display: block;
}

/* LIVE */

input[type="range"]::-webkit-slider-thumb {
  background-color: red;
  color: red;
}

/* Default styling for the range input */
#myRange {
  -webkit-appearance: none;
  /* Override default appearance */
  width: 100%;
  /* Full-width */
  height: 25px;
  /* Specified height */
  background: #d3d3d3;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity.2s;
}

#myRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default appearance */
  appearance: none;
  width: 15px;
  /* Set a specific width */
  height: 15px;
  /* Slider handle height */
  background: red;
  border-radius: 50% !important;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

/* delete profile modal */

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.modal-message {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
}

.btn-confirm,
.btn-cancel {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.btn-confirm {
  background-color: #d9534f;
  color: white;
}

.btn-confirm:hover {
  background-color: #c9302c;
}

.btn-cancel {
  background-color: #5bc0de;
  color: white;
}

.btn-cancel:hover {
  background-color: #31b0d5;
}

.bg-primary {
  background-color: #0770BF;
  border-color: #0770BF;
}

.bg-primary:hover {
  background-color: transparent;
  border-color: #0770BF;
  color: #0770BF;
}

.top-navbar {
  background-color: #00477C;
  color: #fff;
}

.banner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../SVG/bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 750px;
}

.grey-color {
  color: #C3C3C3;
}

.input-box {
  position: relative;
  margin-left: 1.5rem;
}

.input-box input {
  outline: 1px solid transparent;
  padding: 0.5rem 2.1rem;
  border-radius: 3px;
  background-color: #F0F4FF;
}

.input-box svg {
  position: absolute;
  left: 4%;
  top: 30%;
}

.edu-icon-box {
  background-color: #00477C;
  color: #ffff;
  border-radius: 8px;
  position: relative;
  top: -4rem;
}

.edu-icon-box .edu-icon {
  border-right: 1px solid #fff;
  margin-block: 2rem;
}

.testimonial-box {
  background-color: #fff;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  width: 95% !important;
}

.testimonial-box .testi-star-box {
  display: flex;
  margin-block: 1rem;
}

.testi-star-box svg {
  margin-right: 0.4rem;
  color: rgba(255, 213, 39, 1);
  font-size: 21px;
}

.testimonial-box .quote-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1rem;
}

.quote-box span {
  font-weight: 500;
  font-size: 18px;
}

.testimonial-wrap .slick-prev {
  top: 103%;
  left: 40%;
  color: #0770BF;
}

.testimonial-wrap .slick-next {
  top: 103%;
  right: 40%;
  color: #0770BF;
}

.testimonial-wrap .slick-dots li button:before {
  font-size: 9px;
  color: #B4B4B4;
}

.testimonial-wrap .slick-dots li.slick-active button:before {
  color: #0770BF;
}

.testimonial-wrap .slick-dots li {
  margin: 0;
}

.best-course {
  padding-block: 2rem 4rem;
}

.best-course-heading {
  margin-bottom: 1.5rem;
}

.best-course-heading h2 {
  font-size: 32px;
  font-weight: 700;
}

.best-course-box {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
}

.best-padding {
  padding: 0.5rem;
}

.best-course-img {
  position: relative;
}

.best-course-img .play_btn {
  position: absolute;
  left: 40%;
  top: 40%;
  cursor: pointer;
}

.best-course-content ul {
  display: flex;
  margin-block: 0.5rem;
}

.best-course-content ul li {
  margin-right: 5px;
}

.best-course-content .best-red {
  border: 1px solid #EC4A5E;
  color: #EC4A5E;
  font-weight: 600;
  padding-inline: 5px;
  border-radius: 2px;
}

.best-course-content .best-blue {
  border: 1px solid #0868C4CC;
  color: #0868C4CC;
  font-weight: 600;
  padding-inline: 5px;
  border-radius: 2px;
}

.best-course-content h3 {
  font-weight: 700;
}

.best-footer {
  background-color: #fff1cc;
  padding: 0.5rem;
  margin-top: 0.5rem;
  color: #4d4d4d;
}

.left-learn-edu {
  width: 30%;
}

.right-learn-edu {
  width: 70%;
  text-align: right;
}

.right-learn-edu img {
  display: inline-block !important;
  width: 70%;
}

.left-learn-icon {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  position: relative;
}

.left-learn-icon svg {
  color: #909395;
  font-size: 20px;
}

.left-learn-icon::after {
  content: "";
  width: 0px;
  height: 80%;
  background-color: #EC4A5E;
  position: absolute;
  left: 45%;
  top: 10%;
}

.left-learn-active svg {
  color: #EC4A5E;
}

.left-learn-active .left-learn-icon::after {
  width: 1px;
}

.left-learn-content {
  width: 100%;
  border-bottom: 1px solid #C8CBCE;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.left-learn-content p {
  color: #909395;
}

.left-learn-content span {
  max-width: 350px;
  display: block;
  display: none;
}

.left-learn-active p {
  font-weight: 600;
  color: black;
}

.left-learn-active span {
  display: block;
}

.bg-about-us {
  background-color: #0a2844;
}

.about-left {
  width: 45%;
}

.about-right {
  width: 45%;
}

.about-right img {
  width: 100%;
}

/*Teacher Page Starts*/
.teacher-head {
  background-color: #054E82;
  padding-top: 3rem;
  color: #fff;
}

.teacher-left {
  width: 50%;
}

.teacher-left h1 {
  line-height: 40px;
}

.teacher-left p {
  margin-block: 0.7rem;
}

.teacher-left button {
  border: 1px solid #ec4a5e;
}

.teacher-left .join-now {
  color: #ec4a5e;
  margin-left: 1rem;
}

.teacher-right {
  width: 50%;
  text-align: right;
}

.teacher-client-wrap {
  padding: 3rem 2rem;
  background-color: #F5F5F5;
}

.teacher-service {
  background-color: #F9F9F9;
  padding-top: 5rem;
  position: relative;
  height: 100vh;
  margin-bottom: 330px;
}

.teacher-service-left {
  width: 30%;
}

.teacher-service-mid {
  width: 30%;
}

.teacher-service-mid img {
  width: 100%;
}

.teacher-service-box {
  margin-bottom: 2.5rem;
}

.teacher-service-box h5 {
  font-weight: 600;
  margin-top: 0.5rem;
}

.teacher-service-box p {
  color: #515151;
}

.teacher-service-right {
  width: 30%;
}

.sell-course {
  padding-block: 3rem;
}

.sell-course p {
  margin-bottom: 0.5rem;
}

.sell-course ul {
  list-style: disc;
  color: #515151;
  padding-left: 1.1rem;
}

.sell-course ul li {
  margin-bottom: 0.5rem;
}

.business-online-wrap {
  background: url("../images/business-online-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  border-radius: 8px;
  padding: 3rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.business-online-box {
  width: 25%;
  border-right: 1px solid #D0D0D0;
}

.business-online-box h2 {
  line-height: 35px;
}

.business-online-wrap :last-child {
  border: none;
}

.business-online-wrap :first-child {
  border: none;
}

.app-section {
  background-color: #fff;
  text-align: center;
  padding: 2rem;
  position: absolute;
}

.app-section .app-heading-section {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.app-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.app-box {
  background-color: #E8F5FF;
  margin-right: 1rem;
  padding: 1rem 1rem 0 1rem;
  text-align: center;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.47);
  margin-bottom: 1.5rem;
}

.app-box h4 {
  font-weight: 600;
  font-size: 20px;
  max-width: 300px;
  margin: 0 auto;
  line-height: 24px;
}

.app-box img {
  display: inline-block;
  margin-top: 1rem;
}

.app-pink-box {
  background-color: #FFE7EA;
  margin-right: 1rem;
  padding: 0 1rem 1rem 1rem;
  text-align: center;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.47);
  margin-bottom: 1.5rem;
}

.app-pink-box img {
  display: inline-block;
  margin-bottom: 1rem;
}

.app-pink-box h4 {
  font-weight: 600;
  font-size: 20px;
  max-width: 300px;
  margin: 0 auto;
  line-height: 24px;
}

.blog-detail {
  padding-block: 4rem;
}

.blog-detail img {
  max-width: 600px;
  max-height: 350px;
  margin-bottom: 1rem;
}



@media screen and (max-width:1124px) {
  .teacher-service {
    height: auto;
    margin-bottom: 0;
  }

  .app-section {
    padding: 0;
    padding-top: 2rem;
    position: static;
  }
}

@media screen and (max-width:1025px) {
  .banner-box {
    height: auto;
    margin-bottom: 40px;
  }

  .edu-icon-box {
    position: static;
  }

  .left-learn-edu {
    width: 45%;
  }

  .right-learn-edu {
    width: 55%;
  }

  .right-learn-edu img {
    width: 90%;
  }

  .edu-icon-box .no-border {
    border: none;
  }

}

@media screen and (max-width:950px) {
  .input-box input {
    display: none;
  }

  .input-box svg {
    display: none;
  }
}

@media screen and (max-width:900px) {
  .teacher-service-left {
    width: 48%;
  }

  .teacher-service-mid {
    display: none;
  }

  .teacher-service-right {
    width: 48%;
  }
}

@media screen and (max-width:700px) {
  .categories-box {
    display: none;
  }

  .teacher-left {
    width: 100%;
  }

  .teacher-right {
    width: 65%;
  }

  .business-online-box {
    width: 50%;
    text-align: center;
    border: none;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width:650px) {
  .left-learn-edu {
    width: 60%;
  }

  .right-learn-edu {
    width: 55%;
  }

  .right-learn-edu {
    width: 90%;
  }

  .about-left {
    width: 30%;
  }

  .about-right {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .left-learn-edu {
    width: 90%;
  }

  .right-learn-edu {
    text-align: center;
  }

  .right-learn-edu img {
    width: 95%;
  }

  .business-online-box {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .teacher-service-left {
    width: 98%;
  }

  .teacher-service-right {
    width: 98%;
  }

  .about-left {
    width: 100%;
  }

  .about-right {
    width: 100%;
  }

  .teacher-right {
    width: 100%;
  }
}
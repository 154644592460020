.content{
    max-height: 0;
overflow: hidden; 
transition: all 0.2s ease-in ;



}

.content.show{
    height: auto;
    max-height: 400px;
    overflow-y: auto;


 transition: all 0.2s ease-in ;

}

